<template>
  <div class="enterprise-settings">
    <el-dialog
      title="小程序设置"
      :visible.sync="dialogFormVisible"
      :before-close="back"
    >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="150px"
        class="demo-ruleForm"
      >
        <el-form-item label="AppId" prop="appid">
          <el-input v-model="ruleForm.appid"></el-input>
        </el-form-item>
        <el-form-item label="Secret" prop="secret">
          <el-input v-model="ruleForm.secret"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="back">取 消</el-button>
        <el-button type="primary" @click="submitForm('ruleForm')"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { getMiniProgramConfig, updateMiniProgramConfig } from '@/service/minipro'
import {
  Message
} from 'element-ui'
export default {
  components: {

  },
  props: ['officialShow'],
  data() {
    return {
      dialogFormVisible: false,
      ruleForm: {
        appid: '',
        secret: ''
      },
      rules: {
        appid: [
          { required: true, message: '请输入appIdID', trigger: 'blur' }
        ],
        secret: [
          { required: true, message: '请输入Secret', trigger: 'blur' }
        ]
      }

    }
  },
  computed: {

  },
  created() {
    this.getMiniProgramConfig()
  },
  mounted() {

  },
  watch: {
    officialShow(val) {
      this.dialogFormVisible = val
    }
  },
  methods: {
    getMiniProgramConfig() {
      getMiniProgramConfig().then(res => {
        this.ruleForm.appid = res.appid
        this.ruleForm.secret = res.secret
      })
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          updateMiniProgramConfig({ ...this.ruleForm }).then(res => {
            this.dialogFormVisible = false
            this.$emit('visableMiniproShow', false)
            Message.success('添加成功')
          }).catch(function (error) {
            // 处理 getJSON 和 前一个回调函数运行时发生的错误
            console.log('发生错误！', error)
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    resetForm(formName) {
      this.$refs[formName].resetFields()
    },
    back() {
      this.dialogFormVisible = false;
      this.$emit('visableMiniproShow', false);
    }
  }
}
</script>

<style lang="less">
@import url("./index.less");
</style>
