import {
    get,
    post
} from '../Api/axios'
import {
    URL_LIST_SMS_TEMPLATE,
    URL_ADD_SMS_TEMPLATE,
    URL_EDIT_SMS_TEMPLATE,
    URL_DELETE_SMS_TEMPLATE,
    URL_REFRESH_SMS_TEMPLATE,
    URL_LIST_SMS_TASK,
    URL_ADD_SMS_TASK,
    URL_FILE_UPLOAD,
    URL_FILE_DOWNLOAD,
    URL_MESSAGE_CONFIG_LIST,
    URL_MESSAGE_CONFIG_ADD,
    URL_MESSAGE_CONFIG_UPDATE,
    URL_MESSAGE_PACKAGE_CALCULATE,
    URL_MESSAGE_SEND_STATUES_STATISTIC,
    URL_MESSAGE_PULL_REPLY_STATUS,
    URL_WECHAT_SHORTMESSAGE_GROUP_INFO
} from '@/Api/api.js'

export function getSmsTemplateList(params = {}) {
    return get(URL_LIST_SMS_TEMPLATE, {
        ...params
    })
}

export function addSmsTemplate(params = {}) {
    return post(URL_ADD_SMS_TEMPLATE, {
        ...params
    })
}

export function editSmsTemplate(params = {}) {
    return post(URL_EDIT_SMS_TEMPLATE, {
        ...params
    })
}

export function refreshSmsTemplate(params = {}) {
    return get(URL_REFRESH_SMS_TEMPLATE, {
        ...params
    })
}

export function delSmsTemplate(params = {}) {
    return get(URL_DELETE_SMS_TEMPLATE, {
        ...params
    })
}

export function getSmsTaskist(params = {}) {
    return get(URL_LIST_SMS_TASK, {
        ...params
    })
}

export function addSmsTask(params = {}) {
    return post(URL_ADD_SMS_TASK, {
        ...params
    })
}

export function uploadFile(params = {}) {
    return post(URL_FILE_UPLOAD, {
        ...params
    })
}

export function downloadFile() {
    return get(URL_FILE_DOWNLOAD, {
    })
}

// URL_MESSAGE_CONFIG_LIST
export function getMessageConfig() {
    return get(URL_MESSAGE_CONFIG_LIST, {
    })
}
// URL_MESSAGE_CONFIG_ADD
export function postAddMessageConfig(params = {}) {
    return post(URL_MESSAGE_CONFIG_ADD, {
        ...params
    })
}

// RL_MESSAGE_CONFIG_UPDATE
export function postUpdateMessageConfig(params = {}) {
    return post(URL_MESSAGE_CONFIG_UPDATE, {
        ...params
    })
}

// URL_MESSAGE_PACKAGE_CALCULATE
export function getMessagePackage(params = {}) {
    return post(URL_MESSAGE_PACKAGE_CALCULATE, {
        ...params
    })
}

// URL_MESSAGE_SEND_STATUES_STATISTIC
export function getMessageSendStatistic(params = {}) {
    return get(URL_MESSAGE_SEND_STATUES_STATISTIC, {
        ...params
    })
}

// URL_MESSAGE_PULL_REPLY_STATUS
export function getMessagePullReplyStatus(params = {}) {
    return get(URL_MESSAGE_PULL_REPLY_STATUS, {
        ...params
    })
}

// URL_WECHAT_SHORTMESSAGE_GROUP_INFO
export function getshortMessageGroupInfo(params = {}) {
    return get(URL_WECHAT_SHORTMESSAGE_GROUP_INFO, {
        ...params
    })
}
