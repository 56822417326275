<template>
  <div class="sms-settings">
    <el-dialog
      title="基本配置"
      :visible.sync="dialogFormVisible"
      :before-close="back"
    >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="150px"
        class="demo-ruleForm"
      >
        <el-form-item label="秘钥secretId" prop="secretId">
          <el-input v-model="ruleForm.secretId"></el-input>
        </el-form-item>
        <el-form-item label="秘钥secretKey" prop="secretKey">
          <el-input v-model="ruleForm.secretKey"></el-input>
        </el-form-item>
        <el-form-item label="短信签名内容" prop="signName">
          <el-input v-model="ruleForm.signName"></el-input>
        </el-form-item>
        <el-form-item label="短信应用ID" prop="sdkAppId">
          <el-input v-model="ruleForm.sdkAppId"></el-input>
        </el-form-item>
        <!-- <el-form-item>
          <el-button type="primary" @click="submitForm('ruleForm')"
            >提交</el-button
          >
        </el-form-item> -->
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="back">取 消</el-button>
        <el-button type="primary" @click="submitForm('ruleForm')"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { getMessageConfig, postUpdateMessageConfig } from '@/service/sms'
import {
  Message
} from 'element-ui'
export default {
  components: {

  },
  props: ['smsShow'],
  data() {
    return {
      dialogFormVisible: false,
      ruleForm: {
        secretId: '',
        secretKey: '',
        sdkAppId: '',
        signName: ''
      },
      rules: {
        secretId: [
          { required: true, message: '请输入秘钥secretId', trigger: 'blur' }
        ],
        secretKey: [
          { required: true, message: '请输入秘钥secretKey', trigger: 'blur' }
        ],
        signName: [
          { required: true, message: '请输入短信签名内容', trigger: 'blur' }
        ],
        sdkAppId: [
          { required: true, message: '请输入短信应用ID', trigger: 'blur' }
        ]
      }

    }
  },
  computed: {

  },
  created() {
    this.getMessageConfig()
  },
  mounted() {

  },
  watch: {
    smsShow(val) {
      this.dialogFormVisible = val
    }
  },
  methods: {
    getMessageConfig() {
      getMessageConfig().then(res => {
        this.ruleForm = res
      })
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          postUpdateMessageConfig({ ...this.ruleForm }).then(res => {
            this.dialogFormVisible = false;
            this.$emit('visableMessageShow', false);
            Message.success('添加成功')
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    resetForm(formName) {
      this.$refs[formName].resetFields()
    },
    back() {
      this.dialogFormVisible = false;
      this.$emit('visableMessageShow', false);
    }
  }
}
</script>

<style lang="less">
@import url("./index.less");
</style>
